import {ATTR_LOADING_EAGER} from '~/lib/const';
import {FC} from 'react';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {useMatches} from '@remix-run/react';

interface CloudImageProps {
  width?: number;
  height?: number;
  className?: string;
  src: string;
  alt: string;
  style?: Record<string, string>;
  faceFocus?: boolean;
  aspectRatio?: number;
  crop?: 'fill' | 'fit' | 'scale' | 'crop' | 'thumb' | 'imagga_crop' | 'pad';
  background?: string;
  quality?: string;
  visibleByDefault?: boolean;
  logicString?: string;
  dpr?: string;
  id?: string;
}

const baseUrl = `https://res.cloudinary.com/dogz7twzq/image/fetch/`;
const dimension: Record<Keys, string> = {
  width: 'w_',
  height: 'h_',
  quality: 'q_',
  crop: 'c_',
  transform: 'g_',
  aspectRatio: 'ar_',
  background: 'b_',
  dpr: 'dpr_', // device pixel ratio
};

type Keys =
  | 'width'
  | 'height'
  | 'quality'
  | 'crop'
  | 'transform'
  | 'aspectRatio'
  | 'background'
  | 'dpr';

function getDimensionString(
  dims: Partial<Record<Keys, number | string | undefined>>,
) {
  return Object.keys(dims)
    .reduce((res, key) => {
      if (typeof dims[key as Keys] !== 'undefined') {
        res.push(`${dimension[key as Keys]}${dims[key as Keys]}`);
      }
      return res;
    }, [] as string[])
    .join(',');
}

function getBaseSrc(src: string, host?: string) {
  return `https://${host || ''}${src}`;
}

export const CloudImage: FC<CloudImageProps> = ({
  width,
  height,
  src,
  alt,
  className = '',
  style,
  faceFocus,
  aspectRatio,
  crop = 'fill',
  background,
  quality = '80',
  visibleByDefault = false,
  logicString = '',
  id = '',
}) => {
  const [root] = useMatches();

  return visibleByDefault ? (
    <img
      alt={alt}
      src={getCloudinarySource({
        src,
        currentPath: root.data.domain,
        env: root.data.ENV.PUBLIC_ENV,
        width,
        height,
        faceFocus,
        aspectRatio,
        crop,
        background,
        quality,
        logicString,
      }) || '/images/no-image.png'}
      width={width}
      height={height}
      className={className}
      style={style}
      loading={ATTR_LOADING_EAGER}
      id={id}
    />
  ) : (
    <LazyLoadImage
      threshold={400}
      delayTime={10}
      visibleByDefault={visibleByDefault}
      alt={alt}
      src={getCloudinarySource({
        src,
        currentPath: root.data.domain,
        env: root.data.ENV.PUBLIC_ENV,
        width,
        height,
        faceFocus,
        aspectRatio,
        crop,
        background,
        quality,
        logicString,
      })}
      width={width}
      height={height}
      className={className}
      style={style}
    />
  );
};

export function getCloudinarySource({
  src,
  currentPath,
  env,
  width,
  height,
  faceFocus,
  aspectRatio,
  crop = 'fill',
  background,
  quality = '80',
  logicString = '',
  dpr,
}: {
  src: string;
  currentPath: string;
  env: string;
  width: number;
  height?: number;
  faceFocus?: boolean;
  aspectRatio?: number;
  background?: string;
  quality?: string;
  crop?: 'fill' | 'fit' | 'scale' | 'crop' | 'thumb' | 'imagga_crop' | 'pad';
  logicString?: string;
  dpr?: string;
}) {
  const isLocal = env === 'development';
  const isCloudResource = (src || '').startsWith('http');
  const logic = logicString
    ? logicString
    : getDimensionString({
        width,
        height,
        crop,
        quality,
        transform: faceFocus ? 'face' : undefined,
        aspectRatio,
        background,
        dpr,
      });
  if (isCloudResource || !isLocal) {
    return `${baseUrl}${logic}/${encodeURIComponent(
      isCloudResource ? src : getBaseSrc(src, currentPath),
    )}`;
  }

  return src;
  // if is http always use cloudinary
  // else use if !local
}
